@use "@angular/material" as mat;
@import "v2_variables";


html,
body {
  height: 100%;
}

body {
  font-family: $main-font-family !important;
  font-size: 14px !important;
  line-height: 1.5;
  overflow-x: hidden;
  //ios specific
  -webkit-overflow-scrolling: auto;
  height: 100vh;
  overflow: hidden;


}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-auto {
  width: auto !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-hidden {
  overflow: hidden !important;
}

.h-300p {
  height: 300px;
}

.h-400p {
  height: 400px;
}

.transition {
  transition: 0.2s;
}

.white {
  color: #fff;
}


// .toolbar-dropdown-menu.mat-mdc-menu-panel {
//   margin-top: 9px;
//   right: 53px !important;
//   border-radius: 0 !important;
//   overflow: hidden;
//   .mat-mdc-menu-content {
//     padding: 0;
//   }
// }

.logo {
  padding-left: 10px;
  font-weight: 700;
  text-decoration: none;
  min-width: calc(#{$sidenav-width} - 60px);
  color: #fff;

  img {
    width: 90px;
  }

  &.mobile {
    min-width: calc(#{$mini-sidenav-width} - 16px);

    span {
      display: none;
    }
  }
}



.app {
  height: 100%;
  padding-top: env(safe-area-inset-top);

  .page-wrapper.mat-drawer-container {
    z-index: unset;

    .mat-drawer-content {
      height: auto;
      overflow: hidden;
    }
  }

  .sidenav {
    width: $sidenav-width;
  }

  .header-toolbar-div {
    background-color: $white;
    height: $top-toolbar-height !important;
    border-bottom: 1px solid $pastel-blue;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1000;
    border-radius: 0 0 8px 8px;
    margin-right: 20px !important;
    margin-left: 20px !important;

    .header-toolbar {
      height: $top-toolbar-height;
      background-color: $white !important;
      border: 1px solid $gray-200;
      border-radius: 0 0 8px 8px;
    }
  }

  .main-content-inner {
    min-height: calc(100vh - #{$footer-toolbar-height} - env(safe-area-inset-top) - 10px);
    background-color: #f5f7fa;
  }

  &.fixed-header {
    .page-wrapper.mat-drawer-container {
      .mat-drawer-content {}
    }

    .header {
      position: fixed;
      z-index: 999;
      width: 100%;
    }

    .sidenav {
      margin-top: env(safe-area-inset-top);
    }

    &.fixed-sidenav {
      overflow: auto;

      .sidenav {
        padding-top: 0;
      }

      .page-wrapper.mat-drawer-container {
        .mat-drawer-content {
          height: 100vh;
          overflow: auto;
        }
      }

      &.fixed-footer {
        .page-wrapper.mat-drawer-container {
          .mat-drawer-content {
            // padding-bottom: $footer-toolbar-height;
            height: calc(100svh - #{$footer-toolbar-height});
          }
        }
      }
    }
  }

  &.fixed-sidenav {
    .sidenav {
      position: fixed;
      z-index: 3;
    }

    .footer {
      transition: 0.3s;
      width: 100%;
    }

    &.compact {
      .footer {
        width: calc(100% - #{$compact-sidenav-width});

        &.full-width {
          width: 100%;
        }
      }
    }

    &.mini {
      .header-toolbar-div {
        margin-left: 20px;
      }

      .footer {
        width: calc(100% - #{$mini-sidenav-width});

        &.full-width {
          width: 100%;
        }
      }

    }
  }

  &.fixed-footer {
    .page-wrapper.mat-drawer-container {
      .mat-drawer-content {
        // padding-bottom: $footer-toolbar-height;
      }
    }

    .sidenav {
      padding-bottom: $footer-toolbar-height;
    }

    .footer {
      position: fixed;
      bottom: 0;
      right: 0;
      transition: 0.3s;
      width: calc(100% - #{$sidenav-width});
      float: right;

      &.full-width {
        width: 100%;
      }
    }

    &.fixed-sidenav {
      .sidenav {
        padding-bottom: 0;
        z-index: 4;
      }
    }
  }

  &.compact {
    .logo {
      min-width: calc(#{$compact-sidenav-width} - 16px);
    }

    .sidenav {
      width: $compact-sidenav-width;
    }

    .user-block {
      img {
        width: 90px;
        height: 90px;
      }
    }

    .menu-item {
      .mat-mdc-button {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 0;
        flex-direction: column !important;
        align-items: center !important;
        height: auto;

        .mat-icon.menu-icon {
          margin: 0;
        }

        .mat-icon.menu-expand-icon {
          top: 28px;
        }

        .menu-title {
          line-height: 24px;
        }
      }
    }

    .sub-menu {
      .mat-mdc-button {
        padding-left: 0 !important;
      }
    }
  }

  &.mini {
    .logo {
      min-width: calc(#{$mini-sidenav-width} - 16px);

      span {
        display: none;
      }
    }

    .sidenav {
      width: $mini-sidenav-width;
    }

    .user-block {
      img {
        width: 45px;
        height: 45px;
      }
    }

    .menu-item {
      .mat-mdc-button {
        // min-width: $mini-sidenav-width;
        min-width: 45px;
        padding: 8px 0;
        flex-direction: column !important;
        align-items: center !important;
        height: auto;

        .mat-icon.menu-icon {
          margin: 0;
          font-size: 24px;
          height: 24px;
          width: 24px;
        }

        .mat-icon.menu-expand-icon {
          top: 4px;
          right: -4px;
        }

        .menu-title {
          display: none;
        }
      }
    }

    .sub-menu {
      .mat-mdc-button {
        padding-left: 0 !important;
      }
    }
  }

  .mat-mdc-dialog-surface {
    border-radius: 8px !important;
  }

}


@media screen and (max-width: 960px) {
  .app {
    .header-toolbar-div {
      margin-right: 0px !important;
      margin-left: 0px !important;
      border-radius: 0px;

      .header-toolbar {
        border-radius: 0px;
      }
    }
  }
}




// logo header
.logo_header {
  img {
    width: 100px;
    // height: 50px;
  }
}







// error display none
.error-display-none .mat-mdc-form-field-bottom-align {
  display: none !important;
}

// all btns heights
// .button {
//   height: 40px !important;
// }
/* round btn class*/
.round_btn {
  border-radius: 20px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

.user-spinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: 2000;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-300;
  border-radius: 3px;
}

.screen_bg {
  background-color: #f3f3f3;
}

.white_bg {
  background-color: #ffffff;
}

.filled {
  background-color: #57bc18 !important;
}

.next_step {
  background-color: #82baeb !important;
}

.phone-number {
  color: #74788d;
  text-decoration: none;
}

.error-display-none .mat-mdc-form-field-bottom-align {
  display: none !important;
}


.cursor-pointer {
  cursor: pointer !important;
}




.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

// dot loader css
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: gray !important;
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 10px;
  margin: 3px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

// dot loader animation
@keyframes dot {
  0% {
    background-color: #00539b;
    transform: scale(1);
  }

  50% {
    background-color: #00539b;
    transform: scale(1.3);
  }

  100% {
    background-color: #00539b;
    transform: scale(1);
  }
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.features-ul-alignment {
  box-sizing: border-box !important;
  padding-left: 14px !important;
}

.btn-disable {
  filter: grayscale(1);
}





.mat-mdc-radio-button .mdc-radio {
  transform: scale(0.9);
}

.mat-datepicker-toggle {
  @include mat.icon-button-density(-4);
  padding-right: 5px !important;
}

::placeholder {
  color: #717680 !important; // replace with $gray-500 
}

.mdc-checkbox__background {
  border-radius: 4px !important;
}

.mat-drawer {
  box-shadow: 0px 3px 3px -1.5px #0A0D120A, 0px 8px 8px -4px #0A0D120A, 0px 20px 24px -4px #0A0D120A !important;
  border: 1px solid $gray-200;
}

.mat-drawer-border-none {
  border: none !important;
}

.cdk-overlay-pane {
  box-shadow: 0px 3px 3px -1.5px #0A0D120A, 0px 8px 8px 4px #0A0D120A, 0px 24px 24px 4px #0A0D120A !important;
  border: 1px solid $gray-200;
  border-radius: 8px;

}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.6) !important;
}

.mat-calendar {
  background-color: $white;
  border-radius: 8px;
}

.popup-footer {
  border-top: 1px solid $pastel-blue;
}

.fit-drawer-content-height {
  .mat-drawer-content {
    height: unset !important;
  }
}


.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: $primary-600 !important;
}

.ng-scrollbar-wrapper,.ng-scrollbar-wrapper:hover{
  --scrollbar-size: 4px;
  --scrollbar-thumb-color:#D5D7DA;
}

.mdc-checkbox__ripple{
  visibility: hidden;
}

.profile-icon{
  height: 40px !important;
  width: 40px !important;
  font-size: 40px !important;
}

@media (max-width: 768px) {
  .bottom-cards{
      width: auto !important;
      background: linear-gradient(20.32deg, #001B33 -17.26%, #006DCC 80.11%);
      padding: 32px 40px 60px 40px !important;
      margin-bottom: -16px !important;
      margin-inline: -16px !important;
      margin-top: 100px !important;
  }
}

.medium-button {
  @include mat.button-density(-1);
}

.input-field-density {
  @include mat.form-field-density(-5);
}

.input-border-8 {
  --mdc-outlined-text-field-container-shape:8px;
  --mdc-filled-button-container-shape:8px;
}

.thick-borders{
  .mdc-notched-outline{
    box-shadow: -0.44px -0.44px 0.44px 0px #B8CCE0, -0.22px -0.22px 0px 0px #FFFFFF, -0.44px -0.44px 0.44px 0px #B8CCE0 inset, -0.22px -0.22px 0px 0px #FFFFFF inset;
    border-radius: 6px;
  }
    .mdc-notched-outline__leading{
      border: 5px solid #E9EAEB !important;
      border-right: none !important;
    }
    .mdc-notched-outline__notch{
      border: none !important;
    }
    .mdc-notched-outline__trailing{
      border: 5px solid #E9EAEB !important;
      border-left: none !important;
    }
}


.medium-button {
  @include mat.button-density(-1);
}

.input-field-density {
  @include mat.form-field-density(-5);
}

.input-border-8 {
  --mdc-outlined-text-field-container-shape:8px;
  --mdc-filled-button-container-shape:8px;
}

.thick-borders{
  .mdc-notched-outline{
    box-shadow: -0.44px -0.44px 0.44px 0px #B8CCE0, -0.22px -0.22px 0px 0px #FFFFFF, -0.44px -0.44px 0.44px 0px #B8CCE0 inset, -0.22px -0.22px 0px 0px #FFFFFF inset;
    border-radius: 6px;
  }
    .mdc-notched-outline__leading{
      border: 5px solid #E9EAEB !important;
      border-right: none !important;
    }
    .mdc-notched-outline__notch{
      border: none !important;
    }
    .mdc-notched-outline__trailing{
      border: 5px solid #E9EAEB !important;
      border-left: none !important;
    }
}


.form-height{
  --mat-form-field-container-height: 32px;
}

.default-err-border {
  .mat-mdc-form-field-error-wrapper{
    padding: 0px !important;
  }
}
.expired-tag{
  color: $scarlet;
}