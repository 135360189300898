@use "@angular/material" as mat;
@include mat.core();


.app {
        @import "./app/theme/styles/skins/uss-theme";
        @include mat.all-component-themes($light-theme);
        @include mat.typography-hierarchy($light-theme);
        @include mat.form-field-density(-4);
        @include mat.radio-density(-4);
        --mdc-outlined-text-field-focus-outline-color:#66B8FF;
        --mdc-outlined-text-field-outline-color:#D5D7DA;
        --mdc-outlined-text-field-container-shape:6px;
        --mdc-filled-button-container-shape:6px;
        --mdc-outlined-text-field-hover-outline-color:#66B8FF;
        --mat-table-row-item-outline-color: #C9CFEE;
        --mat-sidenav-scrim-color:rgba(255,255,255,0.6);
        --mdc-dialog-container-color:#FFFFFF;
        --mat-select-panel-background-color:#FFFFFF;
        --mat-option-selected-state-layer-color:#fafafa;
        --mat-option-focus-state-layer-color:#fafafa;
        --mat-option-hover-state-layer-color:#fafafa;
        --mat-divider-color:#D5D7DA;
        --mat-sidenav-content-background-color:#F5F7FA;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
// Oswald font
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");



@import 'app/theme/styles/custom_bootstrap';
// @import "node_modules/bootstrap/scss/bootstrap";
@import "app/theme/styles/base";
// @import "app/theme/styles/spaces";





// Toastr
@import "ngx-toastr/toastr";


