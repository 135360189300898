$main-font-family: "Lato", sans-serif;

$top-toolbar-height: 80px;
$horizontal-menu-height: 64px;
$footer-toolbar-height: 56px;

$sidenav-width: 240px;
$compact-sidenav-width: 240px;
$mini-sidenav-width: 120px;

// new variable colors
$primary: #00539b;
$primary-50:#E5F3FF;
$primary-300:#66B8FF;
$primary-600:#006DCC;
$primary-700:#005299;
$primary-800:#003666;
$primary-text: #1f2937;
$success: #16a34a;
$secondary-blue: #006dcc;
$destructive: #dc2626;
$warning: #f59e0b;
$pastel-blue: #c9cfee;
$stroke-blue: #9dbad9;
$uss-primary-dark: #093066;
$uss-green: #00a94f;
$uss-yellow: #FFD964;
$white: #ffffff;
$secondary: #8392ab;
$secondary-text: #717680;

$gray-50: #fafafa;
$gray-100: #F5F5F5;
$gray-200: #e9eaeb;
$gray-300:#D5D7DA;
$gray-400: #a4a7ae;
$gray-500:#717680;
$gray-600: #535862;
$gray-700: #414651;
$gray-800: #252b37;
$gray-900: #181D27;
$gray-modern-900: #121926;

$light-steel-gray: #535862;
$slate-gray: #495057;
$soft-white: #fdfdfd;
$obsidian-gray: #0a0d120d;
$cloud-gray: #d5d7da;
$pearl-gray: #f0f0f1;
$blush-pink: #fee4e2;
$transparent-crimson: #b4231880;
$scarlet: #b42318;
$deep-charcoal: #181d27;
$misty-gray: #61656c80;
$storm-gray: #61656c;
$graphite-green: #414651;
$emerald-green: #06764780;
$forest-green: #067647;
$mint-cream: #dcfae6;
$steel-gray: #717680;



$Eerie-Black: #1e1e1e;
$Davys-Grey: #555555;
$danger: #fda29b;
$danger-100: #fee4e2;
$Neautral-700: #374151;
$Neautral-800: #1f2937;
$light: #ffffff;
$pastel-blue-100: #cce7ff;
$light-sea-green: #17b26a;

// dimension varibale
$form-field-radius: 6px;

$border-color: #d5d7da;
$table-header-color: #fafafa;

$success-100:#DCFAE6;
$success-200:#ABEFC6;
$success-500: #17B26A;
$success-600: #099250;
$success-800:#085D3A;

$warning-100:#FEF0C7;
$warning-300:#FEC84B;
$warning-700:#B54708;


$error-100:#FEE4E2;
$error-300:#FDA29B;

$icon-bg:#E5ECF6;
$sel:#F3F9FF;
$border-selected:#00539B80;
$utility-blue-300:#84CAFF;
$purple-100:#EBE9FE;
$purple-300:#BDB4FE;
$purple-800:#4A1FB8;
$pink-100:#FCE7F6;
$pink-300:#FAA7E0;
$pink-700:#C11574;



